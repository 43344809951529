import { isBrowser } from '@/utils/environment';
import { navigateBack, navigateTo } from '@/utils/navigateTo';
import { useFeatureVideos } from '@gik/api/acf/gik-settings/featureVideos';
import { useUserPages } from '@gik/api/inkinds/userPages';
import { useInkindSituations } from '@gik/api/situation/situation';
import { useEmailAlreadyTaken } from '@gik/api/users/auth';
import { getUser } from '@gik/api/users/user';
import type { LoginProvider } from '@gik/auth/utils/LoginProvider';
import { useSuggestedProducts } from '@gik/checkout/api';
import withSuspense from '@gik/core/components/suspended/withSuspense';
import { timeoutDefaultValue } from '@gik/core/constants';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type { User } from '@gik/core/models/gik/User';
import type { IWordpressCreateFlowCategory } from '@gik/core/models/wordpress/WordpressSituation';
import routes from '@gik/core/routes';
import { useIdempotencyKeysStore } from '@gik/core/store/IdempotencyKeysStore';
import { useUserStore } from '@gik/core/store/UserStore';
import { blurApp } from '@gik/core/utils/AppUtils';
import bemBlock from '@gik/core/utils/bemBlock';
import { useHideIntercomDefaultLauncher } from '@gik/core/utils/Intercom';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import sleep from '@gik/core/utils/sleep';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import AboutStep, { legalDrawerLoading } from '@gik/create/components/AboutStep/AboutStep';
import CareCalendarStep from '@gik/create/components/CareCalendarStep/CareCalendarStep';
import CreatePageNavigation from '@gik/create/components/CreatePageNavigation/CreatePageNavigation';
import CreatePageSplitLayout from '@gik/create/components/CreatePageSplitLayout/CreatePageSplitLayout';
import DonationsStep from '@gik/create/components/DonationsStep/DonationsStep';
import FeaturesAside from '@gik/create/components/FeaturesAside/FeaturesAside';
import FinishStep from '@gik/create/components/FinishStep/FinishStep';
import InKindPageBackground from '@gik/create/components/InKindPageBackground/InKindPageBackground';
import NameYourPageStep from '@gik/create/components/PageStep/NameYourPageStep';
import PrivacyStep from '@gik/create/components/PrivacyStep/PrivacyStep';
import RecipientInfoStep from '@gik/create/components/RecipientInfoStep/RecipientInfoStep';
import SituationStep, { selectedCategoryStorageKey } from '@gik/create/components/SituationStep/SituationStep';
import WishlistStep from '@gik/create/components/WishlistStep/WishlistStep';
import { createPageFlow } from '@gik/create/controllers/createPageController';
import { AboutStepInputNames } from '@gik/create/enums/AboutStepInputNames';
import { CreateFormSteps } from '@gik/create/enums/CreateFormSteps';
import { EmailTakenStatus } from '@gik/create/enums/EmailTakenStatus';
import { FeatureCareCalendarInputNames } from '@gik/create/enums/FeatureCareCalendarInputNames';
import { FeatureWishlistInputNames } from '@gik/create/enums/FeatureWishlistInputNames';
import { RecipientType } from '@gik/create/enums/RecipientType';
import useCreateAnalytics from '@gik/create/hooks/useCreateAnalytics';
import { translationKeys } from '@gik/create/i18n/en';
import type { ICreatePageContext } from '@gik/create/interfaces/ICreatePageContext';
import type { ICreateFlowFormValues } from '@gik/create/models/CreateFlowFormValues';
import { useCreateFlowStore } from '@gik/create/store/CreateFlowStore';
import i18n from '@gik/i18n';
import { Button } from '@gik/ui/Button';
import { CenterFixed } from '@gik/ui/CenterFixed';
import { Drawer } from '@gik/ui/Drawer';
import type { FormRef } from '@gik/ui/Form';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { Modal, MODAL_ANIMATION_LENGTH } from '@gik/ui/Modal';
import type { StepItem } from '@gik/ui/Steps';
import { Steps, SubStep } from '@gik/ui/Steps';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import ClipboardCheck from '@heroicons/react/solid/ClipboardCheckIcon';
import DocumentTextIcon from '@heroicons/react/solid/DocumentTextIcon';
import EyeOffIcon from '@heroicons/react/solid/EyeOffIcon';
import HeartIcon from '@heroicons/react/solid/HeartIcon';
import SparklesIcon from '@heroicons/react/solid/SparklesIcon';
import UserCircleIcon from '@heroicons/react/solid/UserCircleIcon';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Scroll from 'react-scroll';
import type Slider from 'react-slick';
import useEffectOnce from 'react-use/lib/useEffectOnce';
import useLocalStorage from 'react-use/lib/useLocalStorage';
import useSearchParam from 'react-use/lib/useSearchParam';
import shallow from 'zustand/shallow';
import PageNameIdeas from '../PageStep/PageNameIdeas';
import { CreatingPageIndicator } from './CreatingPageIndicator';

const scroller = Scroll.scroller;

export const createPageGroupIdParameter = 'groupId';

const blockName = 'create-page';

// we use a context to store refs instead of zustand since context changes do not trigger a redraw
export const CreatePageContext = React.createContext<ICreatePageContext>(null);

export const stepIndexStorageKey = 'gik-create-form-step-index';
export const subStepIndexStorageKey = 'gik-create-form-sub-step-index';

export enum CreatePageFormIds {
  WishListStepForm = 'WishListStepForm',
  CareCalendarStepForm = 'CareCalendarStepForm',
  DonationsStepForm = 'DonationsStepForm',
}

export function CreatePageFallback() {
  const isMd = useBreakpoint(Breakpoint.MD);
  const createProgress = useCreateFlowStore(store => store.createProgress);
  const createProgressType = useCreateFlowStore(store => store.createProgressType);

  return (
    <>
      <InKindPageBackground />
      {renderPortal(
        <CenterFixed>
          {createProgress > 0 ? (
            <CreatingPageIndicator progress={createProgress} type={createProgressType} />
          ) : (
            <LoadingSpinner variant={isMd ? 'primary' : 'default'} />
          )}
        </CenterFixed>,
        () => document.body
      )}
    </>
  );
}

function CreatePageComp() {
  const bem = bemBlock(blockName);
  const { t } = useTranslation();
  const drawerBem = bemBlock('create-page-drawer');
  const pageNameBem = bemBlock('create-page-sheet-page-name-ideas');

  const _idempotencyKey = useIdempotencyKeysStore(state => state.createFlowIdempotencyKey);
  // moving it to a local state will prevent the idempotency key from changing even if another create flow has
  // completed on another tab, making the only real option to create a new page being to or navigating to the create
  // page again or refreshing the page
  const [idempotencyKey] = React.useState<string>(_idempotencyKey);

  const userId = useUserStore(state => state.id);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const situationCarouselRef = useCreateFlowStore(state => state.situationCarouselRef);
  const groupId = useSearchParam(createPageGroupIdParameter);

  const router = useRouter();

  const { formValues, setFormValues } = useCreateFlowStore(
    state => ({
      formValues: state.formValues,
      setFormValues: state.setFormValues,
    }),
    shallow
  );

  const steps: StepItem<CreateFormSteps>[] = [
    {
      prepend: <SvgIcon Icon={UserCircleIcon} />,
      name: i18n.t(translationKeys.AboutTitle),
      id: CreateFormSteps.ABOUT,
    },
    {
      prepend: <SvgIcon Icon={HeartIcon} />,
      name: i18n.t(translationKeys.SituationTitle),
      id: CreateFormSteps.SITUATION,
    },
    {
      prepend: <SvgIcon Icon={DocumentTextIcon} />,
      name: i18n.t(translationKeys.PageTitle),
      id: CreateFormSteps.PAGE,
      subSteps: formValues.recipientType != RecipientType.Myself ? [{}, {}] : undefined,
    },
    {
      prepend: <SvgIcon Icon={SparklesIcon} />,
      name: i18n.t(translationKeys.FeaturesTitle),
      id: CreateFormSteps.FEATURES,
      subSteps: [{}, {}, {}],
    },
    {
      prepend: <SvgIcon Icon={EyeOffIcon} />,
      name: i18n.t(translationKeys.PrivacyTitle),
      id: CreateFormSteps.PRIVACY,
    },
  ];

  if (!userId) {
    // only need the "finish" step if the user is not logged in
    steps.push({
      prepend: <SvgIcon Icon={ClipboardCheck} />,
      name: i18n.t(translationKeys.FinishTitle),
      id: CreateFormSteps.FINISH,
    });
  }

  const {
    trackIntercomClicked,
    trackCalendarVideoPlayed,
    trackWishlistVideoPlayed,
    trackDonationsVideoPlayed,
    trackOnFinish,
    trackClickedPageNameIdeas,
    trackTermsOfService,
    trackPrivacyPolicy,
  } = useCreateAnalytics(steps);

  const {
    stepIndex,
    setStepIndex,
    subStepIndex,
    setSubStepIndex,
    setSuggestedProducts,
    setSituations,
    setSituationCategories,
    setEmailTakenStatus,
    resetFormValues,
    legalTextDrawerTitle,
    legalTextDrawerCopy,
    closeLegalTextDrawer,
    isPageNameDrawerOpen,
    closePageNameDrawer,
    setNextDisabled,
    nextDisabled,
  } = useCreateFlowStore(
    state => ({
      stepIndex: state.stepIndex,
      setStepIndex: state.setStepIndex,
      subStepIndex: state.subStepIndex,
      setSubStepIndex: state.setSubStepIndex,
      setSuggestedProducts: state.setSuggestedProducts,
      setSituations: state.setSituations,
      setSituationCategories: state.setSituationCategories,
      setEmailTakenStatus: state.setEmailTakenStatus,
      resetFormValues: state.resetFormValues,
      legalTextDrawerTitle: state.legalTextDrawerTitle,
      legalTextDrawerCopy: state.legalTextDrawerCopy,
      closeLegalTextDrawer: state.closeLegalTextDrawer,
      isPageNameDrawerOpen: state.isPageNameDrawerOpen,
      closePageNameDrawer: state.closePageNameDrawer,
      setNextDisabled: state.setNextDisabled,
      nextDisabled: state.nextDisabled,
    }),
    shallow
  );

  const isMd = useBreakpoint(Breakpoint.MD);
  const { mutate: mutateUserPages } = useUserPages(userId);

  const isDrawerOpen = legalTextDrawerTitle !== null && legalTextDrawerCopy !== null;
  const isDrawerLoading = legalTextDrawerCopy === legalDrawerLoading;
  const selectedCategoryIndex = useCreateFlowStore(state => state.selectedCategoryIndex);
  const situationCategories = useCreateFlowStore(state => state.situationCategories);
  const setNewUserLoginProvider = useCreateFlowStore(state => state.setNewUserLoginProvider);

  const timeoutRef = React.useRef<NodeJS.Timeout>(null);

  function getSituationCategories() {
    let allSituationCategories: IWordpressCreateFlowCategory[] = [];

    situations.forEach(situation => {
      if (!situation.acf.create_flow_categories) {
        return;
      }
      allSituationCategories = allSituationCategories.concat(situation.acf.create_flow_categories);
    });

    return allSituationCategories
      .reduce((unique: IWordpressCreateFlowCategory[], item: IWordpressCreateFlowCategory) => {
        return unique.some(e => e.value === item.value) ? unique : [...unique, item];
      }, [])
      .sort((a, b) => a.value.localeCompare(b.value)); // sort by values (format = weighting-taxonomyname)
  }

  useHideIntercomDefaultLauncher();

  const { data: suggestedProducts } = useSuggestedProducts(formValues.situations);
  React.useEffect(() => {
    setSuggestedProducts?.(suggestedProducts);
    // eslint-disable-next-line
  }, [suggestedProducts]);

  const { data: situations } = useInkindSituations({
    page: 1,
    perPage: 100,
  });
  React.useEffect(() => {
    if (!situations) {
      return;
    }
    setSituations?.(situations);
    setSituationCategories(getSituationCategories());
    // eslint-disable-next-line
  }, [situations]);

  const { data: emailTakenStatus } = useEmailAlreadyTaken(formValues[AboutStepInputNames.Email]);
  React.useEffect(() => {
    if (emailTakenStatus) {
      if (!emailTakenStatus.exist) {
        setEmailTakenStatus?.(EmailTakenStatus.FREE);
      } else {
        if (emailTakenStatus.facebook) {
          setEmailTakenStatus?.(EmailTakenStatus.FACEBOOK);
        } else if (emailTakenStatus.google) {
          setEmailTakenStatus?.(EmailTakenStatus.GOOGLE);
        } else {
          setEmailTakenStatus?.(EmailTakenStatus.GIK);
        }
      }
    }
    // eslint-disable-next-line
  }, [emailTakenStatus]);

  const aboutFormRef = React.useRef<FormRef>(null);
  const situationFormRef = React.useRef<FormRef>(null);
  const footerSituationCarouselRef = React.useRef<Slider>(null);
  const careCalendarFormRef = React.useRef<FormRef>(null);
  const wishlistFormRef = React.useRef<FormRef>(null);
  const donationsFormRef = React.useRef<FormRef>(null);
  const donationsFormAsidePayPalRef = React.useRef<FormRef>(null);
  const donationsFormAsideGoFundMeRef = React.useRef<FormRef>(null);
  const donationsFormAsideCashAppRef = React.useRef<FormRef>(null);
  const donationsFormAsideVenmoRef = React.useRef<FormRef>(null);
  const privacyFormRef = React.useRef<FormRef>(null);
  const pageFormRef = React.useRef<FormRef>(null);
  const recipientFormRef = React.useRef<FormRef>(null);
  const finishFormRef = React.useRef<FormRef>(null);

  const [persistent, setPersistent, clearPersistent] = useLocalStorage<ICreateFlowFormValues>(bem());
  const [, , clearSelectedCategoryStorage] = useLocalStorage<string>(selectedCategoryStorageKey);
  const [stepIndexStorage, setStepIndexStorage, clearStepIndexStorage] = useLocalStorage<number>(stepIndexStorageKey);
  //eslint-disable-next-line
  const [subStepIndexStorage, setSubStepIndexStorage, clearSubStepIndexStorage] =
    useLocalStorage<number>(subStepIndexStorageKey);

  const [open, setOpen] = React.useState<boolean>(false);

  function _setStepIndex(stepIndex: number) {
    setStepIndex(stepIndex);
    setStepIndexStorage(stepIndex);
  }

  function _setSubStepIndex(stepIndex: number) {
    setSubStepIndex(stepIndex);
    setSubStepIndexStorage(stepIndex);
  }

  function clearStorage() {
    clearSelectedCategoryStorage();
    clearStepIndexStorage();
    clearSubStepIndexStorage();
  }

  function openDialog() {
    blurApp();
    setTimeout(() => {
      setOpen(true);
    }, 600);
  }

  useEffectOnce(() => {
    const waitForUITimeout = 500;
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);

      let recipientType: string = null;
      if (
        urlParams.has('for') &&
        Object.values(RecipientType)
          .map(v => v.toString())
          .includes(urlParams.get('for'))
      ) {
        recipientType = urlParams.get('for');
      }

      if (isBrowser() && persistent) {
        await sleep(waitForUITimeout);

        blurApp();

        const now = new Date();

        const continueFromPersistence = await UI.confirm(i18n.t(translationKeys.PersistenceDialogBody), {
          title: i18n.t(translationKeys.PersistenceDialogTitle),
          okText: i18n.t(translationKeys.PersistenceDialogOkText),
          cancelText: i18n.t(translationKeys.PersistenceDialogCancelText),
          closable: true,
        });

        const timeTaken = new Date().getTime() - now.getTime();

        if (continueFromPersistence === true) {
          setFormValues(persistent);
          openDialog();

          let stepIndex = stepIndexStorage;

          if (isNaN(stepIndex)) {
            stepIndex = 0;
          }
          if (stepIndex === 5 && !!userId) {
            _setStepIndex(stepIndex - 1);
          } else {
            _setStepIndex(stepIndex);
          }

          let subStepIndex = subStepIndexStorage;
          if (isNaN(subStepIndex)) {
            subStepIndex = 0;
          }
          _setSubStepIndex(subStepIndex);

          // if this step's values were saved in storage that means they were successfully submitted previously
          // meaning they had to be valid, so we can just co ahead and set the next button to enabled
          setTimeout(() => {
            setNextDisabled(false);
          });
        } else if (continueFromPersistence === false) {
          clearPersistent();
          clearStorage();
          resetFormValues();
          openDialog();
        } else {
          if (timeTaken > 500) navigateBack();
        }
      } else {
        if (recipientType) {
          setFormValues({ ...formValues, recipientType } as ICreateFlowFormValues);
        }
        openDialog();
      }
    })();
  });

  React.useEffect(() => () => setOpen(false), []);

  // prefetch it here so it's already available for the features step
  useFeatureVideos();

  function getFeaturesStepIndex(): number {
    return steps.findIndex(steps => steps.id === CreateFormSteps.FEATURES);
  }

  const pageStepIndex = steps.findIndex(steps => steps.id === CreateFormSteps.PAGE);

  function getCurrentFormsRef(stepIndex: number) {
    const featuresStepIndex = getFeaturesStepIndex();

    const forms = [
      aboutFormRef,
      situationFormRef,
      null, // page forms
      null, // featuresForms
      privacyFormRef,
      finishFormRef,
    ];

    const featuresForms = [careCalendarFormRef, wishlistFormRef, donationsFormRef];
    const featuresAsideForms = [
      [],
      [],
      [
        donationsFormAsidePayPalRef.current,
        donationsFormAsideGoFundMeRef.current,
        donationsFormAsideVenmoRef.current,
        donationsFormAsideCashAppRef.current,
      ],
    ];
    const pageForms = [pageFormRef, recipientFormRef];

    if (stepIndex === featuresStepIndex) {
      return [featuresForms[subStepIndex]?.current, ...featuresAsideForms[subStepIndex]];
    } else if (stepIndex === pageStepIndex) {
      return [pageForms[subStepIndex]?.current];
    } else {
      return [forms[stepIndex]?.current];
    }
  }

  const hasNext = !(
    stepIndex === steps.length - 1 &&
    ((steps[stepIndex]?.subSteps && subStepIndex === steps[stepIndex]?.subSteps?.length - 1) ||
      !steps[stepIndex]?.subSteps)
  );

  async function areFormsValid(forms: FormRef[]): Promise<boolean> {
    let valid = forms
      ?.filter(form => !!form)
      ?.every(form => {
        return form.isValid;
      });

    if (
      formValues?.includeRecipientAddress &&
      (!formValues?.address1 || !formValues?.city || !formValues?.state || !formValues?.postalCode)
    ) {
      valid = false;
    }

    return valid;
  }

  function areFormsValidating(forms: FormRef[]): boolean {
    return forms?.every(form => {
      return form?.isValidating;
    });
  }

  async function validateAndSubmitCurrentForm(step: number = stepIndex): Promise<boolean> {
    const currentForms = getCurrentFormsRef(step);
    if (currentForms) {
      for (const form of currentForms) {
        if (form) {
          await sleep(500);

          if (!form?.submitForm) return true;
          await form?.submitForm();
        }
      }
    }
    return await areFormsValid(currentForms);
  }

  async function handleFormChange(values) {
    // const formRef = getCurrentFormsRef(stepIndex);
    // const result = await areFormsValid(formRef);
    // if (values) setFormValues(values);
    // if (
    //   values?.includeRecipientAddress &&
    //   (!values?.address1 || !values?.city || !values?.state || !values?.postalCode)
    // ) {
    //   setNextDisabled(true);
    // }
    // check again to be sure the values are up to date
    // TODO: use the form state subscription api instead of actively checking it as its more reliable
    // await sleep(100);
    // setNextDisabled(!(await areFormsValid(formRef)));
  }

  function tryValidation() {
    const formRef = getCurrentFormsRef(stepIndex);
    const validating = areFormsValidating(formRef);

    if (validating) {
      // try again in a bit
      setTimeout(tryValidation, 100);
    } else {
      // FIXME: without delay, form will be validated before it is rendered, causing incorrect result
      setTimeout(() => handleFormChange?.(formValues), timeoutDefaultValue);
    }
  }

  useEffect(() => {
    tryValidation();
    // eslint-disable-next-line
  }, [stepIndex, subStepIndex]);

  async function handleStepSubmit(values: ICreateFlowFormValues) {
    Object.keys(values).forEach(key => values[key] === undefined && delete values[key]);
    const newValues: ICreateFlowFormValues = { ...formValues, ...values };

    if (JSON.stringify(formValues).localeCompare(JSON.stringify(newValues)) !== 0) {
      setFormValues(newValues);
      setPersistent(newValues);
    }
  }

  async function handleSubmitOnChange(values: ICreateFlowFormValues) {
    handleFormChange(values);
    await handleStepSubmit(values);
  }

  async function handleClick(_step: StepItem<CreateFormSteps>, goToStepIndex: number, goToSubIndex: number) {
    _setStepIndex(goToStepIndex);
    _setSubStepIndex(goToSubIndex);
  }

  async function handleNext() {
    const currentForm = getCurrentFormsRef(stepIndex);
    let contextError = null;

    let notificationError: string;
    if (
      currentForm?.[0].id === CreatePageFormIds.CareCalendarStepForm &&
      formValues.careCalendarEnabled === undefined
    ) {
      notificationError = t(translationKeys.MissingFeatureSelection, { name: t(translationKeys.careCalendarTitle) });
    }
    if (currentForm?.[0].id === CreatePageFormIds.WishListStepForm && formValues.wishlistEnabled === undefined) {
      notificationError = t(translationKeys.MissingFeatureSelection, { name: t(translationKeys.WishlistTitle) });
    }
    if (currentForm?.[0].id === CreatePageFormIds.DonationsStepForm && formValues.donationsEnabled === undefined) {
      notificationError = t(translationKeys.MissingFeatureSelection, { name: t(translationKeys.FundraisingTitle) });
    }

    // the user must select either calendar or wishlist before they are allowed to continue
    if (
      currentForm?.[0].id === CreatePageFormIds.WishListStepForm &&
      formValues[FeatureWishlistInputNames.WishlistEnabled] !== true &&
      formValues[FeatureCareCalendarInputNames.CareCalendarEnabled] !== true
    ) {
      contextError = t(translationKeys.CantDisableCareCalendarAndWishlist);
      UI.notifyError(contextError);
    }

    const areFormsValid = await validateAndSubmitCurrentForm(stepIndex);

    if (!areFormsValid || contextError || nextDisabled) {
      return;
    }

    if (steps[stepIndex]?.subSteps && subStepIndex < steps[stepIndex].subSteps.length - 1) {
      _setSubStepIndex(subStepIndex + 1);
    } else {
      _setStepIndex(Math.min(stepIndex + 1, steps.length - 1));
      _setSubStepIndex(0);
    }
  }

  function handleBack() {
    if (steps[stepIndex]?.subSteps && subStepIndex > 0) {
      _setSubStepIndex(Math.max(subStepIndex - 1, 0));
    } else {
      _setStepIndex(Math.max(stepIndex - 1, 0));
      if (steps[stepIndex - 1]?.subSteps) {
        _setSubStepIndex(steps[stepIndex - 1].subSteps.length - 1);
      }
    }
  }

  async function handleCancel() {
    if (window?.location.pathname == routes.create) {
      const response = await UI.confirm(i18n.t(translationKeys.CreatePageCancelConfirmation), {
        title: i18n.t(translationKeys.CreatePageCancelConfirmationTitle),
        okText: i18n.t(translationKeys.CreatePageCancelConfirmationOkButton),
        cancelText: i18n.t(translationKeys.CreatePageCancelConfirmationCancelButton),
      });

      if (response) {
        setOpen(false);

        // wait for create modal to fully close before triggering navigation
        setTimeout(() => {
          navigateAway();
        }, MODAL_ANIMATION_LENGTH);
      }
    }
  }

  React.useEffect(() => () => clearTimeout(timeoutRef.current), []);

  function navigateAway() {
    if (document.referrer && new URL(document.referrer).origin === window.location.origin) {
      history.back();
    } else {
      navigateBack();
    }

    timeoutRef.current = setTimeout(() => {
      if (router.route == routes.create) {
        navigateAway();
      }
    }, 1000);
  }

  async function handleLoginSignup() {
    return new Promise<User>(resolve => {
      const unsub = useUserStore.subscribe(
        (id: string) => {
          (async () => {
            const user = await getUser(id);
            resolve(user);
            unsub();
          })();
        },
        state => state.id
      );
    });
  }

  async function startPageCreation(provider?: LoginProvider) {
    if (provider) {
      setNewUserLoginProvider(provider);
    }
    setIsLoading(true);

    const response = await createPageFlow(
      handleLoginSignup,
      trackOnFinish,
      mutateUserPages,
      clearPersistent,
      clearStorage,
      resetFormValues,
      groupId,
      idempotencyKey
    );

    if (response?.redirectUrl) {
      // will update the store state but not the local state so if another tab has opened before completing here, it
      // will not be updated there and fail to create the page with an already used idempotency key
      useIdempotencyKeysStore.getState().updateCreateFlowIdempotencyKey();
      navigateTo(response.redirectUrl);
    }
  }

  async function handleFinish() {
    if (await validateAndSubmitCurrentForm()) {
      // only start page creation if we are already logged in
      // otherwise, we will wait for the onSuccess of the finish step to fire.
      if (userId) {
        startPageCreation();
      }
    }
  }

  // TODO: The modal should be created using thge UI manager to create the modal so modalsheets can be created more easily
  const drawerPortal = isBrowser() ? (document.getElementsByClassName(bem())?.[0] as HTMLElement) : undefined;

  const isDataLoading = !situations;
  const isError = isDataLoading && situations;

  if (isLoading) {
    return <CreatePageFallback />;
  }
  if (isError) {
    return <CreatePageFallback />;
  }

  return (
    <CreatePageContext.Provider
      value={{
        aboutFormRef,
        situationFormRef,
        footerSituationCarouselRef,
        careCalendarFormRef,
        wishlistFormRef,
        donationsFormRef,
        donationsFormAsidePayPalRef,
        donationsFormAsideGoFundMeRef,
        donationsFormAsideCashAppRef,
        donationsFormAsideVenmoRef,
        privacyFormRef,
        pageFormRef,
        recipientFormRef,
        finishFormRef,
        handleBack,
        handleCancel,
        handleNext,
        handleFinish,
      }}
    >
      <InKindPageBackground />
      {isLoading && (
        <CenterFixed>
          <LoadingSpinner variant={isMd ? 'primary' : 'default'} />
        </CenterFixed>
      )}
      {isBrowser() && <ThumbnailPreFetcher />}
      {!isLoading && (
        <Modal
          allowIntercom
          bodyOpenClassName={bem('body-modal-open')}
          className={bem(null, [{ [`step-${steps[stepIndex]?.id}`]: true }, { [`substep-${subStepIndex}`]: true }])}
          isOpen={open}
          onClose={handleCancel}
          backButtonOverrideEnabled={false}
          pages={[
            {
              title: i18n.t(translationKeys.CreateTitle),
              pageArrows: stepIndex === 1,
              pageArrowNextDisabled: selectedCategoryIndex >= situationCategories?.length - 1,
              pageArrowPrevDisabled: selectedCategoryIndex === 0,
              onArrowNext: () => {
                situationCarouselRef.slickNext();
              },
              onArrowPrev: () => {
                situationCarouselRef.slickPrev();
              },
              content: (
                <>
                  <Steps
                    className={bem('steps')}
                    allowBackwardNavigation
                    allowForwardNavigation={false}
                    index={stepIndex}
                    subIndex={subStepIndex}
                    onClick={handleClick}
                    steps={steps}
                  >
                    <AboutStep
                      initialValues={formValues}
                      onSubmit={handleStepSubmit}
                      onChange={handleFormChange}
                      trackTermsOfService={trackTermsOfService}
                      trackPrivacyPolicy={trackPrivacyPolicy}
                    />

                    <SituationStep initialValues={formValues} onSubmit={handleStepSubmit} onChange={handleFormChange} />

                    {formValues[AboutStepInputNames.RecipientType] !== RecipientType.Myself ? (
                      <SubStep>
                        <NameYourPageStep
                          onSubmit={handleStepSubmit}
                          onChange={handleFormChange}
                          trackClickedPageNameIdeas={trackClickedPageNameIdeas}
                        />

                        <RecipientInfoStep onSubmit={handleStepSubmit} onChange={handleFormChange} />
                      </SubStep>
                    ) : (
                      <NameYourPageStep
                        onSubmit={handleStepSubmit}
                        onChange={handleFormChange}
                        trackClickedPageNameIdeas={trackClickedPageNameIdeas}
                      />
                    )}

                    <SubStep>
                      <CreatePageSplitLayout className={bem('features-split-layout')}>
                        <main>
                          <CareCalendarStep
                            id={CreatePageFormIds.CareCalendarStepForm}
                            initialValues={formValues}
                            onSubmit={handleStepSubmit}
                            onChange={handleSubmitOnChange}
                          />
                        </main>
                        <FeaturesAside
                          onChange={handleSubmitOnChange}
                          trackCalendarVideoPlayed={trackCalendarVideoPlayed}
                        />
                      </CreatePageSplitLayout>

                      <CreatePageSplitLayout className={bem('features-split-layout')}>
                        <main>
                          <WishlistStep
                            id={CreatePageFormIds.WishListStepForm}
                            initialValues={formValues}
                            onSubmit={handleStepSubmit}
                            onChange={handleSubmitOnChange}
                          />
                        </main>
                        <FeaturesAside
                          onChange={handleSubmitOnChange}
                          trackWishlistVideoPlayed={trackWishlistVideoPlayed}
                        />
                      </CreatePageSplitLayout>

                      <CreatePageSplitLayout className={bem('features-split-layout')}>
                        <main>
                          <DonationsStep
                            id={CreatePageFormIds.DonationsStepForm}
                            initialValues={formValues}
                            onSubmit={handleStepSubmit}
                            onChange={handleSubmitOnChange}
                          />
                        </main>
                        <FeaturesAside
                          onChange={handleSubmitOnChange}
                          trackDonationsVideoPlayed={trackDonationsVideoPlayed}
                        />
                      </CreatePageSplitLayout>
                    </SubStep>

                    <PrivacyStep onChange={handleSubmitOnChange} />

                    <FinishStep onSubmit={handleStepSubmit} onSuccess={startPageCreation} />
                  </Steps>
                  <Drawer
                    portal={drawerPortal}
                    placement="bottom"
                    variant="neutral"
                    shadow
                    isOpen={isDrawerOpen}
                    onClose={() => closeLegalTextDrawer()}
                    className={drawerBem(null, null, 'gik-modal-drawer')}
                    padded
                    title={isDrawerLoading ? <>&nbsp;</> : <HTMLParser rawHtml={legalTextDrawerTitle} />}
                  >
                    {isDrawerLoading ? (
                      <div className={drawerBem('loading-container')}>
                        <LoadingSpinner center />
                      </div>
                    ) : (
                      /* TODO: UI Article component */
                      <div className={drawerBem('copy', undefined, ['gik-article'])}>
                        <HTMLParser rawHtml={legalTextDrawerCopy} />
                      </div>
                    )}
                  </Drawer>
                  <Drawer
                    portal={drawerPortal}
                    placement="bottom"
                    variant="neutral"
                    shadow
                    closeBtn={false}
                    isOpen={isPageNameDrawerOpen}
                    onClose={() => closePageNameDrawer()}
                    className={drawerBem(null, null, 'gik-modal-drawer')}
                    padded
                    footer={({ close }) => <Button onClick={close}>Close</Button>}
                  >
                    <div className={pageNameBem()}>
                      <span className={pageNameBem('title')}>
                        Here are some ideas for Page Names to help you get started:
                      </span>
                      <PageNameIdeas
                        situationIds={formValues.situations}
                        onSelect={(pageName: string) => {
                          closePageNameDrawer();
                          pageFormRef.current?.setValues({ pageName });
                        }}
                      />
                    </div>
                  </Drawer>
                </>
              ),
            },
          ]}
          footer={
            <CreatePageNavigation
              className={bem('navigation')}
              stepIndex={stepIndex}
              hasNext={hasNext}
              trackIntercomClicked={trackIntercomClicked}
            />
          }
        />
      )}
    </CreatePageContext.Provider>
  );
}

export const CreatePage = withComponentErrorBoundary(withSuspense(CreatePageComp, CreatePageFallback));

/**
 * loads gift card and situations thumbnails in advance providing a *much* smoother experience :)
 */
function ThumbnailPreFetcher() {
  const suggestedProducts = useCreateFlowStore(state => state.suggestedProducts);
  const situations = useCreateFlowStore(state => state.situations);
  const bem = bemBlock(blockName);

  return (
    <div className={bem('thumbnail-prefetcher')}>
      {suggestedProducts?.map((product, i) => <img key={i} alt="prefetch" src={product?.gridImage} />)}
      {situations?.map((situation, i) => <img key={i} alt="prefetch" src={situation?.acf?.taxonomy_svg_icon} />)}
    </div>
  );
}
